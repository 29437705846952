import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getContributionRatioConditions() {
    return axiosIns.get('/admin/contribution_ratio_conditions')
  },
  createContributionRatioCondition({
    adjective,
    threshold,
    withTax,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      contributionRatioCondition: {
        adjective,
        threshold,
        withTax,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.post('/admin/contribution_ratio_conditions', formData)
  },
  updateContributionRatioCondition({
    id,
    adjective,
    threshold,
    withTax,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      contributionRatioCondition: {
        adjective,
        threshold,
        withTax,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.put(`/admin/contribution_ratio_conditions/${id}`, formData)
  },
  deleteContributionRatioCondition(id) {
    return axiosIns.delete(`/admin/contribution_ratio_conditions/${id}`)
  },
}
